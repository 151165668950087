import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { NotSuperAdminGuard } from './core/auth/guards/notSuperAdmin.guard';
import { RoleGuard } from './core/auth/guards/role.guard';
import { CustomerAccessGuard } from './modules/customer-details/customer-access-guard.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    //{path: '', pathMatch : 'full', redirectTo: 'dashboard'},
    {path: '', pathMatch : 'full', redirectTo:  'welcome' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    //{path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},
    {path: 'signed-in-redirect', pathMatch : 'full',redirectTo: 'dashboard' },
    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'welcome', loadChildren: () => import('app/modules/landing-page/landing-page.module').then(m => m.LandingPageModule)},
            {path: 'mobile/welcome', loadChildren: () => import('app/modules/landing-page/mobile/landing.mobile.module').then(m => m.LandingMobileModule)},
            {path: 'splashscreen/:token/:isCloned', loadChildren: () => import('app/modules/splash-screen/splashscreen.module').then(m => m.SplashScreenModule)},
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
            {path: 'disabled-user', loadChildren: () => import('app/modules/auth/disabled-user/disabled-user.module').then(m => m.DisabledUserModule)},
            {path: 'reset-password/:email/:code/:operation', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule)},
            {path: 'forbidden-access', loadChildren: () => import('app/modules/auth/forbidden-Access/forbidden-access.module').then(m => m.ForbiddenAccessModule)},
            {path: 'cgu', loadChildren: () => import('app/modules/cgu-validation/cgu-validation.module').then(m => m.CguValidationModule)},
            {path: 're-send-token', loadChildren: () => import('app/modules/auth/re-send-token/re-send-token.module').then(m => m.ReSendTokenModule)},
            {path: 'partner-selection', loadChildren: () => import('app/modules/auth/partner-selection-welcoming-page/partner-selection-welcoming-page.module').then(m => m.PartnerSelectionWelcomingPageModule)},
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)},
            { path: 'not-found', loadChildren: () => import('app/modules/auth/not-found/not-found.module').then(m => m.NotFoundModule) },
            { path: 'error-page', loadChildren: () => import('app/modules/auth/error-page/error-page.module').then(m => m.ErrorPageModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            //{path: 'home', loadChildren: () => import('app/modules/dashboard/dashboard/dashboard.module').then(m => m.DashboardModule)},
        ]
    },

    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver
        },
        children   : [
            {path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule), canLoad: [AuthGuard, NotSuperAdminGuard]},
            {path: 'prospects', loadChildren: () => import('app/modules/prospects/prospects.module').then(m => m.ProspectsModule) ,canLoad: [AuthGuard, NotSuperAdminGuard]},
            {path: 'prospects/import', loadChildren: () => import('app/modules/prospects/prospects.module').then(m => m.ProspectsModule) ,canLoad: [AuthGuard, NotSuperAdminGuard]},
            //{path: 'customers', loadChildren: () => import('app/modules/customers/customers.module').then(m => m.CustomersModule)},
            //{path: 'folders', loadChildren: () => import('app/modules/folders/folders.module').then(m => m.FoldersModule)},
            {path: 'onboarding', loadChildren: () => import('app/modules/onboarding/onboarding.module').then(m => m.OnboardingModule)},
            //{path: 'schedule', loadChildren: () => import('app/modules/schedule/schedule.module').then(m => m.ScheduleModule)},
            //{path: 'simulator', loadChildren: () => import('app/modules/simulator/simulator.module').then(m => m.SimulatorModule)},
            {path: 'prospects/customer-details/devis', loadChildren: () => import('app/modules/devis/devis.module').then(m => m.DevisModule), canLoad: [AuthGuard, NotSuperAdminGuard, RoleGuard]},
            {path: 'prospects/customer-details/devis/:id', loadChildren: () => import('app/modules/devis/devis.module').then(m => m.DevisModule), canLoad: [AuthGuard, NotSuperAdminGuard], canActivate: [CustomerAccessGuard]},
            {path: 'prospects/customer-details/invoice', loadChildren: () => import('app/modules/invoice/invoice.module').then(m => m.InvoiceModule), canLoad: [AuthGuard, NotSuperAdminGuard, RoleGuard]},
            {path: 'prospects/customer-details/invoice/:id', loadChildren: () => import('app/modules/invoice/invoice.module').then(m => m.InvoiceModule), canLoad: [AuthGuard, NotSuperAdminGuard], canActivate: [CustomerAccessGuard]},
            {path: 'prospects/customer-details', loadChildren: () => import('app/modules/customer-details/customer-details.module').then(m => m.CustomerDetailsModule), canLoad: [AuthGuard, NotSuperAdminGuard]},       
            {path: 'prospects/customer-details/:id', loadChildren: () => import('app/modules/customer-details/customer-details.module').then(m => m.CustomerDetailsModule), canLoad: [AuthGuard, NotSuperAdminGuard], canActivate: [CustomerAccessGuard]},
            {path: 'rdvs', loadChildren: () => import('app/modules/settings/rdv/rdv.module').then(m => m.RDVModule), canLoad: [AuthGuard, NotSuperAdminGuard, RoleGuard]},
            {path: 'map', loadChildren: () => import('app/modules/settings/appointments-map/appointments-map.module').then(m => m.AppointmentsMapModule ), canLoad: [AuthGuard, NotSuperAdminGuard, RoleGuard]},
            {path: 'settings', loadChildren: () => import('app/modules/settings/settings.module').then(m => m.SettingsModule),canLoad: [AuthGuard]},
            {path: 'partners/drop', loadChildren: () => import('app/modules/partners/new-drop/partners-drop.module').then(m => m.PartenersDropModule),canLoad: [AuthGuard, NotSuperAdminGuard]},
            {path: 'partners/drop/:id', loadChildren: () => import('app/modules/partners/new-drop/partners-drop.module').then(m => m.PartenersDropModule),canLoad: [AuthGuard, NotSuperAdminGuard]},
            {path: 'partners/drop-list', loadChildren: () => import('app/modules/partners/drop-list/partner-drop-list.module').then(m => m.PartenerDropListModule),canLoad: [AuthGuard, RoleGuard, NotSuperAdminGuard]},
            {path: 'partners/drop-list/MPR', loadChildren: () => import('app/modules/partners/drop-list/partner-drop-list.module').then(m => m.PartenerDropListModule),canLoad: []},
            {path: 'partners/dashboard', loadChildren: () => import('app/modules/partners/dashboard/partners-dashboard.module').then(m => m.PartnersDashboardModule),canLoad: [AuthGuard, RoleGuard, NotSuperAdminGuard]},
            { path: 'partners/drop-details', loadChildren: () => import('app/modules/partners/drop-details/partners-drop-details.module').then(m => m.PartnersDropDetailsModule),canLoad: [AuthGuard, RoleGuard, NotSuperAdminGuard]},
            { path: 'partners/drop-details/:id', loadChildren: () => import('app/modules/partners/drop-details/partners-drop-details.module').then(m => m.PartnersDropDetailsModule),canLoad: [AuthGuard,RoleGuard, NotSuperAdminGuard]},
            { path: 'partners/invoices', loadChildren: () => import('app/modules/partners/partners-invoice/partner-invoices-list.module').then(m => m.PartenerInvoiceListModule),canLoad: [AuthGuard, RoleGuard, NotSuperAdminGuard]},
            { path: 'partners/contracts', loadChildren: () => import('app/modules/partners/partners-contracts/partners-contracts.module').then(m => m.PartnersContractsModule),canLoad: [AuthGuard, NotSuperAdminGuard]},
            { path: 'partners/chats', loadChildren: () => import('app/modules/partners/conversation/conversation.module').then(m => m.ConversationModule),canLoad: [NotSuperAdminGuard]},
        ]
    }
];
