import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SupportRequest } from 'app/modules/models/supportRequest.type';
import { SupportRequestService } from 'app/shared/services/supportRequest.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { DatePipe } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { isSuperAdmin } from 'app/shared/utils';
import { Router } from '@angular/router';
import { StorageItemService } from 'app/shared/services/storageItem.service';
import { StorageItem } from 'app/modules/models/storageItem.type';

export const FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-add-support-request',
  templateUrl: './add-support-request.component.html',
  styleUrls: ['./add-support-request.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: FORMATS },
  ],
})
export class AddSupportRequestComponent implements OnInit, OnDestroy {

  supportRequestForm: FormGroup;
  selectedSupportRequest: SupportRequest;
  selectedFolderNode: string;
  connectedUser: User;
  isSuperAdmin = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  priorities = ['Basse', 'Moyenne', 'Haute', 'Très haute'];
  statuses = ['Nouveau', 'En cours de traitement', 'Résolu', 'Clos'];
  requestTypes = ['Ajout d\'un Produit','Ajout de document' ,'Question', 'Problème technique', 'Demande d\'évolution'];
  DisallowedExtensions: string[] = ['exe', '.sh', '.dll', '.bat', '.sh', '.com']; // Disallowed extensions here
  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  };
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() action = 'add';
  selectedFile: File | null = null;
  files = [];
  attachedDocuments: File[] = [];
  isLoadingFiles = false;

  @Output()
  reloadData = new EventEmitter<boolean>();

  @Output()
  closeDrawer = new EventEmitter<boolean>();

  constructor(private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _supportRequestService: SupportRequestService,
    private _datePipe: DatePipe,
    private _userService: UserService,
    private _storageService: StorageItemService) { }

  ngOnInit(): void {
    this.initForm();
    this.isSuperAdmin = isSuperAdmin();
    this._userService.connectedUser$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.connectedUser = user;
    });

    this._supportRequestService.selectedRequest$.pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
      this.selectedSupportRequest = result;
      this.getDocuments();
      this.editForm();
    });

  }

  handleFilesInput(event: any): void {
    this.isLoadingFiles = true;
    this.checkFiles(event.target.files);
  }

  initForm(): void {
    this.supportRequestForm = this._formBuilder.group({
      type: ['', Validators.required],
      priority: [''],
      status: ['Nouveau'],
      resolutionDate: [''],
      object: ['', Validators.required],
      description: [''],
    });
  }

  editForm(): void {
    if (this.selectedSupportRequest && this.action === 'edit') {
      this.supportRequestForm = this._formBuilder.group({
        priority: [{ value: this.selectedSupportRequest.priority, disabled: false }, Validators.required],
        resolutionDate: [{ value: this._datePipe.transform(this.selectedSupportRequest.resolutionDate, 'dd/MM/yyyy') !== '01/01/0001' ? this.selectedSupportRequest.resolutionDate : ' ', disabled: true }],
        status: [{ value: this.selectedSupportRequest.status, disabled: false }],
        type: [{ value: this.selectedSupportRequest.type, disabled: true }, Validators.required],
        object: [{ value: this.selectedSupportRequest.object, disabled: true }],
        description: [{ value: this.selectedSupportRequest.description, disabled: true }],
      });
      this.supportRequestForm.get('object').disable();
    }
  }

  checkFiles(uploadedFile: File[]): void {
    if (uploadedFile.length > 0) {
      for (let i = 0; i < uploadedFile.length; i++) {
        const fileExtension = uploadedFile[i].name.split('.').pop()?.toLowerCase();
        if (fileExtension && !this.DisallowedExtensions.includes(fileExtension)) {

          this.selectedFile = uploadedFile[i];
          this.attachedDocuments.push(uploadedFile[i]);
          this.files.push({
            name: this.selectedFile.name,
            mimetype: this.getMimeType(this.selectedFile.type),
            size: this.selectedFile.size,
            file: uploadedFile[i],
          });
        } else {
          this._snackBar.open('Format de fichier invalide..', null, {
            duration: 2000,
            panelClass: 'warn-snackbar',
          });
        }
      };
    }

    this.isLoadingFiles = false;
  }

  getMimeType(type: string): void {
    let types = {
      'application/pdf': () => 'PDF',
      'image/webp': () => 'WEBP',
      'image/png': () => 'PNG',
      'image/jpeg': () => 'JPG',
      'image/gif': () => 'GIF',
      'text/plain': () => 'TXT',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': () => 'XLS',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': () => 'PPT',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': () => 'DOC',
    };
    let action = types[type] || (() => 'FILE');
    return action();
  }

  deleteItemByNode(item): void {
    this.fileInput.nativeElement.value = '';
    this.files = this.files.filter(result => result != item);
    this.attachedDocuments = this.attachedDocuments.filter(result => result != item.file);
  }

  editSupportRequest(): void {
    let demand: SupportRequest = {
      type: this.supportRequestForm?.get('type').value,
      object: this.supportRequestForm?.get('object').getRawValue(),
      description: this.supportRequestForm?.get('description').value,
      companyId: this.selectedSupportRequest.companyId,
      userId: this.selectedSupportRequest.userId,
      status: this.action === 'add' ? 'Nouveau' : this.supportRequestForm?.value?.status,
      priority: this.supportRequestForm?.value?.priority,
      requestNumber: this.selectedSupportRequest.requestNumber,
      documentsFolder: this.selectedSupportRequest.documentsFolder,
      id: this.selectedSupportRequest.id,
      folderId: this.selectedSupportRequest.folderId

    }
    if (this.supportRequestForm?.get('resolutionDate').value && this.supportRequestForm?.get('resolutionDate').value !== ' ') {
      demand.resolutionDate = this.supportRequestForm?.get('resolutionDate').getRawValue();
    }
    this._supportRequestService.updateSupportRequest(demand).subscribe({
      next: result => {
        this.supportRequestForm?.reset();
        this.files = [];
        this.reloadData.emit(true);
        this._snackBar.open('La demande a été modifiée avec succès', null, {
          duration: 2000,
          panelClass: 'accent-snackbar',
        });

      },
      error: error => {
        this._snackBar.open('Une erreur serveur est survenue.', null, {
          duration: 2000,
          panelClass: 'warn-snackbar',
        });
      }
    });
  }

  toggleDrawer(): void {
    this.supportRequestForm?.reset();
    this.files = [];
    this.closeDrawer.emit(true);
  }

  onStatusChange(event): void {
    if (event.value === 'Résolu') {
      this.supportRequestForm.get('resolutionDate').setValue(new Date());
    } else if (event.value === 'Clos' && this._datePipe.transform(this.selectedSupportRequest.resolutionDate, 'dd/MM/yyyy') !== '01/01/0001') {
    }
    else {
      this.supportRequestForm.get('resolutionDate').setValue(null);
    }
  }

  getDocuments(): void {
    if (this.selectedSupportRequest && this.action === 'edit') {
      this._storageService.getFolderContent(null, JSON.parse(localStorage.getItem("currentCompany")).id, "SUPPORT", this.selectedSupportRequest.folderId).subscribe({
        next: result => {
          this.files = [];
          result.forEach(element => {
            this.files.push({
              name: element.name,
              mimetype: element.name.split('.').pop()?.toUpperCase(),
              file: element,
              storageItemParentId: element.storageItemParentId,
              folderId: element.folderId
            });
          });
        },
        error: error => {
          this._snackBar.open('Une erreur serveur est survenue.', null, {
            duration: 2000,
            panelClass: 'warn-snackbar',
          });
        },
      });
      
    }
  }

  //handle already saved files
  clickDownloadSavedFile(file: StorageItem) {
    var fileExtension = file?.extension ? file?.extension.toLowerCase() : '';
    this._storageService.getStorageItemByNodeId(file.storageItemParentId, file.name).subscribe((response: any) => {
      var base64Format = response.content;
      this.downloadFile(base64Format, fileExtension, file.name);
    }, error => {
    });
  }

  downloadFile(base64File, extension, fileName): void {
    const byteCharacters = atob(base64File);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/' + extension });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  sendDemand(): void {
    let demand: SupportRequest = {
      type: this.supportRequestForm?.value?.type,
      object: this.supportRequestForm?.value?.object,
      description: this.supportRequestForm?.value?.description,
      companyId: JSON.parse(localStorage.getItem("currentCompany")).id,
      userId: this.connectedUser.id,
      status: 'Nouveau'
    }

    this._supportRequestService.addSupportRequest(demand).subscribe({
      next: result => {
        try {
        if (this.files.length > 0) {
          this.files.forEach(file => {
            this._storageService.uploadFile(
              file.file,
              file.name,
              null,
              demand.companyId,
              "SUPPORT",
              result.folderId,
              "00000000-0000-0000-0000-000000000000"
              ).subscribe({
              next: result => {
                this._snackBar.open('Votre demande a été envoyée avec succès.', null, {
                  duration: 2000,
                  panelClass: 'accent-snackbar',
                });
              },
              error: error => {
                this._snackBar.open('Une erreur serveur est survenue.', null, {
                  duration: 2000,
                  panelClass: 'warn-snackbar',
                });
              },
            });
          });
          
        } else {
          this._snackBar.open('Votre demande a été envoyée avec succès.', null, {
            duration: 2000,
            panelClass: 'accent-snackbar',
          });
        } 
        
        
      } catch (error) {
        this._snackBar.open('Une erreur serveur est survenue.', null, {
          duration: 2000,
          panelClass: 'warn-snackbar',
        });
      } finally {
        this.supportRequestForm?.reset();
          this.files = [];
          this._supportRequestService.reload(true);
          this.closeDrawer.emit(true);
      }

      },
      error: error => {
        this._snackBar.open('Une erreur serveur est survenue.', null, {
          duration: 2000,
          panelClass: 'warn-snackbar',
        });
      },
    });
  }

  goToSupport(): void {
    this.closeDrawer.emit(true);
    this._router.navigate(['/settings/support-requests']);
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
