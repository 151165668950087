<div class="fixed lg:sticky bottom-0 lg:left-full w-full sm:w-96 lg:w-16 h-16 lg:shadow rounded-xl"
    [ngClass]="{'h-160': opened, 'translate-x-0': !opened}">
    <div class="flex flex-col w-full sm:w-96 h-full transition-transform duration-400 ease-drawer bg-card rounded-b"
        [ngClass]="{'-translate-x-full sm:-translate-x-96 lg:-translate-x-80 shadow': opened, 'translate-x-0 bg-primary': !opened}">

        <!-- Header -->
        <div class="quick-chat-header flex flex-0 items-center justify-start cursor-pointer" (click)="toggle()">

            <!-- Toggle -->
            <ng-container *ngIf="!opened || (opened && !selectedChat)">
            
                <div class="flex flex-auto items-center justify-center -mt-3 rounded-xl"
                    [matTooltip]="'Consulter la discussion avec le partenaire'" matTooltipPosition="left">
                    <span class="absolute top-0 left-0 flex h-2 w-2 mt-4 ml-10" *ngIf="!opened && active">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-accent-200 opacity-85"></span>
                        <span class="relative inline-flex rounded-full h-2 w-2 bg-accent-500"></span>
                    </span>
                    <div class="flex flex-0 items-center justify-center w-16">
                        <mat-icon class="icon-size-6  text-white" [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                    </div>
                    <button class="ml-auto mr-4" mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container>

            <!-- Contact info -->
            <ng-container *ngIf="opened && selectedChat">
                <div class="flex flex-auto items-center ml-3 ">
                    <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                            <button class="ml-auto mr-4" mat-icon-button (click)="openConversation($event)">
                                <mat-icon [svgIcon]="'heroicons_outline:arrows-expand'"></mat-icon>
                            </button>                        
                    </div>
                    <div class="ml-4 text-lg font-medium leading-5 truncate">Messagerie</div>
                    <button class="ml-auto mr-4" mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>

        <!-- Content -->
        <div class="flex flex-auto border-t overflow-hidden">

            <!-- Chat list -->
            <div *ngIf="false"
                class="flex-0 w-16 h-full overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
                fuseScrollbar [fuseScrollbarOptions]="{wheelPropagation: false}">
                <div class="flex-auto">
                    <ng-container *ngFor="let chat of chats; trackBy: trackByFn">
                        <div class="flex items-center py-3 px-4 cursor-pointer" [ngClass]="{'hover:bg-primary-100 dark:hover:bg-hover': !selectedChat || selectedChat.id !== chat.id,
                                        'bg-primary-50 dark:bg-hover': selectedChat && selectedChat.id === chat.id}"
                            (click)="selectChat(chat.id)">
                            <div class="relative flex flex-0 items-center justify-center w-8 h-8">
                                <ng-container *ngIf="chat.unreadCount > 0">
                                    <div class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-primary-900 bg-primary dark:bg-primary-500 text-on-primary"
                                        [class.ring-primary-50]="selectedChat && selectedChat.id === chat.id"></div>
                                </ng-container>
                                <ng-container *ngIf="chat.contact?.avatar">
                                    <img class="w-full h-full rounded-full object-cover" [src]="chat?.contact?.avatar"
                                        alt="Contact avatar" />
                                </ng-container>
                                <ng-container *ngIf="!chat.contact?.avatar">
                                    <div
                                        class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-primary-200 text-primary-600 dark:bg-primary-700 dark:text-primary-200">
                                        {{chat.contact?.name?.charAt(0)}}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- Conversation -->
            <div class="flex flex-col flex-auto border-l overflow-hidden bg-primary-50 dark:bg-transparent">
                <ng-container *ngIf="chat; else selectChatOrStartNew">
                    <div class="flex flex-col-reverse h-full overflow-y-auto overscroll-y-contain">
                        <div class="flex flex-col flex-auto shrink">
                            <ng-container
                                *ngFor="let message of chat.messages; let i = index; let first = first; let last = last; trackBy: trackByFn">
                                <!-- Start of the day -->
                                <ng-container
                                    *ngIf="first || (chat.messages[i - 1].createdAt | date:'d') !== (message.createdAt | date:'d')">
                                    <div class="flex items-center justify-center my-3 -mx-6">
                                        <div class="flex-auto border-b"></div>
                                        <div class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                                            {{message.createdAt | date: 'longDate'}}
                                        </div>
                                        <div class="flex-auto border-b"></div>
                                    </div>
                                </ng-container>
                                <div class="flex flex-col" [ngClass]="{'items-end': !message.isMine,
                                                'items-start': message.isMine,
                                                'mt-0.5': i > 0 && chat.messages[i - 1].isMine !== message.isMine,
                                                'mt-3': i > 0 && chat.messages[i - 1].isMine === message.isMine}">
                                    <!-- Bubble -->
                                    <div class="relative max-w-3/4 px-3 py-2 rounded-lg" [ngClass]="{'bg-accent-500 text-accent-50': message.isMine,
                                                    'bg-primary-500 text-primary-50': !message.isMine}">
                                        <!-- Speech bubble tail -->
                                        <ng-container *ngIf="last || chat.messages[i + 1].isMine !== message.isMine">
                                            <div class="absolute bottom-0 w-3"
                                                [ngClass]="{' text-primary-500 t -right-1 -mr-px mb-px': !message.isMine,
                                                            ' text-accent-500 -left-1 -ml-px mb-px -scale-x-1': message.isMine}">
                                                <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                                            </div>
                                        </ng-container>
                                        <!-- Message -->
                                        <div class="min-w-4 leading-5" [innerHTML]="message.value">
                                        </div>
                                    </div>
                                    <!-- Time -->
                                    <ng-container *ngIf="first
                                       || last
                                       || chat.messages[i + 1].isMine !== message.isMine
                                       || chat.messages[i + 1].createdAt !== message.createdAt">
                                        <div class="my-0.5 text-sm font-medium text-secondary" [ngClass]="{'mr-3': !message.isMine,
                                                        'ml-3': message.isMine}">
                                           {{message?.userNameCrea}} {{message.createdAt | date:'HH:mm'}}
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <!-- Message field -->
                    <div class="flex p-4 border-t bg-primary-50 dark:bg-transparent">
                        <div class="flex items-center justify-center w-full ">
                            <button mat-stroked-button class="w-full" [color]="'accent'" [disabled]="!active"
                            [matTooltip]="!active ? 'Messagerie non accessible pour ce dossier' : ''"
                            [matTooltipDisabled]="active"
                            (click)="openConversation($event)"> 
                                <mat-icon class="rotate-90  mx-2" [svgIcon]="'heroicons_outline:paper-airplane'">
                                </mat-icon>
                                Répondre
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Select chat or start new template -->
<ng-template #selectChatOrStartNew>
    <div class="flex flex-col flex-auto items-center justify-center w-full h-full p-4">
        <mat-icon class="icon-size-24" [svgIcon]="'heroicons_outline:chat'"></mat-icon>
        <div class="mt-4 text-xl text-center font-medium tracking-tight text-secondary">Select a conversation</div>
    </div>
</ng-template>

<!-- Speech bubble tail SVG -->
<!-- @formatter:off -->
<ng-template #speechBubbleExtension>
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
                fill="currentColor" fill-rule="nonzero"></path>
        </g>
    </svg>
</ng-template>
<!-- @formatter:on -->