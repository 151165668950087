import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class NotSuperAdminGuard implements CanActivate, CanActivateChild, CanLoad {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        // return this._check(redirectUrl);
        return true;
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        // return this._check(redirectUrl);
        return true;
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        const helper = new JwtHelperService();
        let myRawToken = localStorage.getItem("accessToken");       
        if (myRawToken != null) {
            const decodedToken = helper.decodeToken(myRawToken);
            
            var role: string[] | string = decodedToken.role
            if (role == undefined) {
                role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            }
            if (typeof role === "string") {
                if (role === "SuperAdmin") {
                  this._router.navigateByUrl('/settings/landing');
                  return false;
                }
            } else {
                if ((role as string[]).some(x => x === "SuperAdmin")) {
                  this._router.navigateByUrl('/settings/landing');
                  return false;
                }
            }
        }
        return true;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): Observable<boolean> {
        // Check the authentication status
        return this._authService.check()
            .pipe(
                switchMap((authenticated) => {

                    // If the user is not authenticated...
                    if (!authenticated) {
                        // Redirect to the sign-in page
                        this._router.navigate(['sign-in'], { queryParams: { redirectURL } });

                        // Prevent the access
                        return of(false);
                    }
                    // Allow the access
                    return of(true);
                })
            );
    }
}
